import React from 'react'
import MainLayout from '../layouts/main-layout'
import SEO from '../components/seo'
import CTA from '../components/cta'
import ImageGrid from '../components/image-grid'
import image2 from '../images/gallery/2.jpg'
import image4 from '../images/gallery/4.jpg'
import image5 from '../images/gallery/5.jpg'
import image6 from '../images/gallery/6.jpg'
import image7 from '../images/gallery/7.jpg'
import image8 from '../images/gallery/8.jpg'
import image9 from '../images/gallery/9.jpg'
import image10 from '../images/gallery/10.jpg'
import image11 from '../images/gallery/11.jpg'
import image12 from '../images/gallery/12.jpg'
import image13 from '../images/gallery/13.jpg'
import image14 from '../images/gallery/14.jpg'
import image15 from '../images/gallery/15.jpg'
import image16 from '../images/gallery/16.jpg'
import image17 from '../images/gallery/17.jpg'
import image18 from '../images/gallery/18.jpg'
import image19 from '../images/gallery/19.jpg'
import image20 from '../images/gallery/20.jpg'
import imageVan2 from '../images/van2.jpg'
import imageVan3 from '../images/van3.jpg'
import imageVan4 from '../images/colin-paul-van.png'
import image21 from '../images/gallery/21.jpg'
import image22 from '../images/gallery/22.jpg'
import image23 from '../images/gallery/23.jpg'

const GalleryPage = () => {
  const images = [image20, image21, image10, image2, imageVan2, image4, image22, image5, image6, image7, image8, image9, image11, image12, image23, image13, imageVan4, image14, image15, image16, image17, imageVan3, image18, image19]

  return (
    <MainLayout titleText="Gallery">
      <SEO title="Gallery" />

      <ImageGrid images={images} />

      <CTA
        titleText="Ready for your new carpet?"
        buttonTo="/contact"
        buttonText="Contact Us"
      />
    </MainLayout>
  )
}

export default GalleryPage
