import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { transparentize } from 'polished'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  justify-content: space-between;

  ${breakpoint('tablet')`
    padding: 2rem;
    padding-bottom: 0;
  `}

  ${breakpoint('desktop')`
    padding: 6rem;
    padding-bottom: 4rem;
  `}
`

export const ImgContainer = styled.div`
  height: 50vh;
  flex: 0 0 100%;
  background-color: #BF2D38;
  background-image: url('${props => props.src}');
  background-image: linear-gradient(to top, ${transparentize(0.8, '#000000')}, ${transparentize(0.8, '#000000')}), url('${props => props.src}');
  background-size: cover;
  background-position: top;
  transition: 0.2s;
  border-radius: 0.5rem;
  box-sizing: border-box;

  &:not(:last-of-type) {
    margin-bottom: 1rem;

    ${breakpoint('tablet')`
      margin-bottom: 2rem;
    `}
  }

  ${breakpoint('tablet')`
    flex: 0 0 calc(50% - 1rem);
  `}

  ${breakpoint('desktop')`
    flex: 0 0 calc(33% - 1rem);
    height: 16rem;
  `}

  /* cursor: pointer;

  &:hover {
    transform: scale(1.05);
  } */
`
