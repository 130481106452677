import React, { useState } from 'react'
import { Container, ImgContainer } from './styles'

const ImageGrid = ({ images }) => {
  const [expanded, expand] = useState(false)

  return (
    <Container>
      {images.map((image) => <ImgContainer onClick={() => expand(!expanded)} src={image} />)}
    </Container>
  )
}

export default ImageGrid
